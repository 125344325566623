import {
  NAV_ITEM_TYPE_COLLAPSE,
  NAV_ITEM_TYPE_ITEM,
} from 'constants/navigation.constant';

const navigationConfig = [
  {
    key: 'home',
    path: '/home',
    title: 'Home',
    translateKey: 'nav.home',
    icon: 'home',
    type: NAV_ITEM_TYPE_ITEM,
    authority: [],
    subMenu: [],
  },
  {
    key: 'VSantosProcessos',
    path: '/processos',
    title: 'Processos',
    translateKey: '',
    icon: 'HiOutlineTemplate',
    type: NAV_ITEM_TYPE_ITEM,
    authority: ['vsantos_customer'],
    subMenu: [],
  },
  {
    key: 'VSantosKPIs',
    path: '/kpis',
    title: 'KPIs',
    translateKey: '',
    icon: 'HiOutlineTemplate',
    type: NAV_ITEM_TYPE_COLLAPSE,
    authority: ['vsantos_customer'],
    subMenu: [
      {
        key: 'kpiAereo',
        path: '/kpi-aereo',
        title: 'KPI Performance Aéreo',
        translateKey: '',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['vsantos_customer'],
        subMenu: [],
      },
      {
        key: 'kpiMaritimo',
        path: '/kpi-maritimo',
        title: 'KPI Performance Marítimo',
        translateKey: '',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['vsantos_customer'],
        subMenu: [],
      },
      {
        key: 'kpiRodoviario',
        path: '/kpi-rodoviario',
        title: 'KPI Performance Rodoviário',
        translateKey: '',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['vsantos_customer'],
        subMenu: [],
      },
    ],
  },
  {
    key: 'VSantosReports',
    path: '/reports',
    title: 'Relatórios',
    translateKey: '',
    icon: 'HiOutlineTemplate',
    type: NAV_ITEM_TYPE_COLLAPSE,
    authority: ['vsantos_customer'],
    subMenu: [
      {
        key: 'reportCustoProcesso',
        path: '/custo-processo',
        title: 'Custo por Processo',
        translateKey: '',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['vsantos_customer'],
        subMenu: [],
      },
      {
        key: 'reportCustoItem',
        path: '/custo-item',
        title: 'Custo por Item',
        translateKey: '',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['vsantos_customer'],
        subMenu: [],
      },
    ],
  },
  {
    key: 'myMap',
    path: '/management-map',
    title: 'Mapa Gerencial',
    translateKey: '',
    icon: 'HiOutlinePencilAlt',
    type: NAV_ITEM_TYPE_ITEM,
    authority: ['user', 'user_cometrix_vsantos'],
    subMenu: [],
  },
  {
    key: 'newQuotation',
    path: '/new-quotation',
    title: 'Nova Cotação',
    translateKey: '',
    icon: 'HiOutlinePencilAlt',
    type: NAV_ITEM_TYPE_ITEM,
    authority: ['user', 'user_cometrix_vsantos'],
    subMenu: [],
  },
  {
    key: 'quotations',
    path: '/quotations',
    title: 'Cotações',
    translateKey: 'nav.quotations',
    icon: 'HiOutlineCalculator',
    type: NAV_ITEM_TYPE_ITEM,
    authority: ['admin'],
    subMenu: [],
  },
  {
    key: 'myQuotations',
    path: '/my-quotations',
    title: 'Minhas Cotações',
    translateKey: '',
    icon: 'HiOutlineCalculator',
    type: NAV_ITEM_TYPE_ITEM,
    authority: [
      'user',
      'operator',
      'user_cometrix_vsantos',
    ],
    subMenu: [],
  },
  {
    key: 'myProcess',
    path: '/my-process',
    title: 'Meus Processos',
    translateKey: '',
    icon: 'HiOutlineTemplate',
    type: NAV_ITEM_TYPE_ITEM,
    authority: ['user', 'user_pharmedical', 'user_cometrix_vsantos'],
    subMenu: [],
  },
  {
    key: 'myFinance',
    path: '/finance',
    title: 'Financeiro',
    translateKey: '',
    icon: 'HiOutlineCurrencyDollar',
    type: NAV_ITEM_TYPE_ITEM,
    authority: ['user', 'user_pharmedical', 'admin', 'user_cometrix_vsantos'],
    subMenu: [],
  },
  {
    key: 'myOrders',
    path: '/orders',
    title: 'Gestão de Pedidos',
    translateKey: '',
    icon: 'HiOutlineDocumentDuplicate',
    type: NAV_ITEM_TYPE_COLLAPSE,
    authority: ['user', 'user_pharmedical', 'user_cometrix_vsantos'],
    subMenu: [
      {
        key: 'myOrders.orderItems',
        path: '/orders/items',
        title: 'Itens de Pedido',
        translateKey: 'nav.reports.myReports',
        icon: 'HiOutlineDocumentText',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [
          'user',
          'user_pharmedical',
          'admin',
          'user_cometrix_vsantos',
        ],
        subMenu: [],
      },
      {
        key: 'myOrders.financeOrderItems',
        path: '/orders/finance',
        title: 'Itens Embarcados',
        translateKey: 'nav.reports.myReports',
        icon: 'HiOutlineDocumentReport',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [
          'user',
          'user_pharmedical',
          'admin',
          'user_cometrix_vsantos',
        ],
        subMenu: [],
      },
    ],
  },
  {
    key: 'shipments',
    path: '/shipments',
    title: 'Embarques',
    translateKey: 'nav.shipments',
    icon: 'HiOutlineTemplate',
    type: NAV_ITEM_TYPE_ITEM,
    authority: ['admin'],
    subMenu: [],
  },
  {
    key: 'myImport',
    path: '/my-shipments?op=I',
    title: 'Minhas Importações',
    translateKey: '',
    icon: 'HiOutlineDownload',
    type: NAV_ITEM_TYPE_ITEM,
    authority: [
      'user',
      'user_pharmedical',
      'agent',
      'operator',
      'user_cometrix_vsantos',
    ],
    subMenu: [],
  },
  {
    key: 'myExport',
    path: '/my-shipments?op=E',
    title: 'Minhas Exportações',
    translateKey: '',
    icon: 'HiOutlineUpload',
    type: NAV_ITEM_TYPE_ITEM,
    authority: [
      'user',
      'user_pharmedical',
      'agent',
      'operator',
      'user_cometrix_vsantos',
    ],
    subMenu: [],
  },
  {
    key: 'myPendingsUser',
    path: '/my-pendings',
    title: 'Minhas Pendências',
    translateKey: '',
    icon: 'HiOutlineExclamation',
    type: NAV_ITEM_TYPE_ITEM,
    authority: [
      'user',
      'user_pharmedical',
      'agent',
      'operator',
      'user_cometrix_vsantos',
    ],
    subMenu: [],
  },
  {
    key: 'myNotificationsUser',
    path: '/my-notifications',
    title: 'Minhas Notificações',
    translateKey: '',
    icon: 'HiOutlineBell',
    type: NAV_ITEM_TYPE_ITEM,
    authority: [
      'user',
      'user_pharmedical',
      'agent',
      'operator',
      'user_cometrix_vsantos',
    ],
    subMenu: [],
  },
  {
    key: 'customers',
    path: '/customers',
    title: 'Single menu item',
    translateKey: 'nav.customers',
    icon: 'HiOutlineUserGroup',
    type: NAV_ITEM_TYPE_ITEM,
    authority: ['admin'],
    subMenu: [],
  },
  {
    key: 'users',
    path: '/users',
    title: 'Single menu item',
    translateKey: 'nav.users',
    icon: 'HiOutlineUserGroup',
    type: NAV_ITEM_TYPE_ITEM,
    authority: ['admin'],
    subMenu: [],
  },

  {
    key: 'reports.myReports',
    path: '/reports/my-reports',
    title: 'Painel de Relatórios',
    translateKey: 'nav.reports.myReports',
    icon: 'HiOutlineChartSquareBar',
    type: NAV_ITEM_TYPE_ITEM,
    authority: ['admin','user', 'user_pharmedical', 'user_cometrix_vsantos'],
    subMenu: [],
  },
  // {
  //     key: 'myIntegrations',
  //     path: '/integration-panel',
  //     title: 'Painel de Integrações',
  //     translateKey: 'nav.reports.myReports',
  //     icon: 'HiOutlineLightningBolt',
  //     type: NAV_ITEM_TYPE_ITEM,
  //     authority: ["user","user_pharmedical","user_pharmedical"],
  //     subMenu: [],
  // },
  {
    key: 'pharmedical',
    path: '/myCustomizations',
    title: 'Meus Cadastros',
    translateKey: 'nav.config',
    icon: 'HiOutlineUserGroup',
    type: NAV_ITEM_TYPE_COLLAPSE,
    authority: ['user_pharmedical'],
    subMenu: [
      {
        key: 'config.customerManufacturer',
        path: '/config/customer-manufacturer',
        title: 'Fabricantes',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: 'config.customerProvider',
        path: '/config/customer-provider',
        title: 'Fornecedores',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: 'config.customerProduct',
        path: '/config/customer-product',
        title: 'Medicamentos',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: 'config.customerCustomer',
        path: '/config/customer-customer',
        title: 'Clientes',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: 'config.customerProposal',
        path: '/config/customer-proposal',
        title: 'Orçamentos',
        translateKey: '',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
    ],
  },
  {
    key: 'VSantosTracking',
    path: '',
    title: 'Tracking V.Santos',
    translateKey: '',
    icon: 'HiOutlineTruck',
    type: NAV_ITEM_TYPE_COLLAPSE,
    authority: ['user_cometrix_vsantos'],
    subMenu: [
      {
        key: 'VSantosProcessos',
        path: '/processos',
        title: 'Processos',
        translateKey: '',
        icon: 'HiOutlineTemplate',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: 'kpiAereo',
        path: '/kpi-aereo',
        title: 'KPI Performance - Aéreo',
        translateKey: '',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: 'kpiMaritimo',
        path: '/kpi-maritimo',
        title: 'KPI Performance - Marítimo',
        translateKey: '',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: 'kpiRodoviario',
        path: '/kpi-rodoviario',
        title: 'KPI Performance - Rodoviário',
        translateKey: '',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: 'reportCustoProcesso',
        path: '/custo-processo',
        title: 'Relatório - Custo por Processo',
        translateKey: '',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: 'reportCustoItem',
        path: '/custo-item',
        title: 'Relatório - Custo por Item',
        translateKey: '',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
    ],
  },
  {
    key: 'ShipmentPendings',
    path: '/shipment-pendings',
    title: 'Importações com Pendências',
    translateKey: '',
    icon: 'HiOutlineExclamation',
    type: NAV_ITEM_TYPE_ITEM,
    authority: ['user', 'user_pharmedical', 'user_cometrix_vsantos'],
    subMenu: [],
  },
  {
    key: 'otherConfigs',
    path: '',
    title: 'Mais Configurações',
    translateKey: '',
    icon: 'HiOutlineCubeTransparent',
    type: NAV_ITEM_TYPE_COLLAPSE,
    authority: ['user', 'user_pharmedical', 'user_cometrix_vsantos'],
    subMenu: [
      {
        key: 'myPartners',
        path: '/agents',
        title: 'Despachantes',
        translateKey: '',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: 'myOperators',
        path: '/operators',
        title: 'Agentes de Carga',
        translateKey: '',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: 'mySuppliers',
        path: '/suppliers',
        title: 'Fornecedores',
        translateKey: '',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: 'pendingCategory',
        path: '/pending-category',
        title: 'Categorias de Pendência',
        translateKey: '',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: 'subpendingCategory',
        path: '/subpending-category',
        title: 'Sub-Categorias de Pendência',
        translateKey: '',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },



      {
        key: 'myUsers',
        path: '/users',
        title: 'Usuários',
        translateKey: '',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: 'journeyConfig',
        path: '/config/journey',
        title: 'Jornadas',
        translateKey: '',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: 'mySettings',
        path: '/settings',
        title: 'Configurações Gerais',
        translateKey: '',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
      {
        key: 'config.customerParameter',
        path: '/config/customer-parameter',
        title: 'Cadastro de Parâmetros',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'historyTractian',
        path: '/historical/tractian',
        title: 'Histórico Tractian',
        translateKey: '',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['email:tractian'],
        subMenu: [],
      },
    ],
  },
  {
    key: 'myPendingsAdmin',
    path: '/my-pendings',
    title: 'Pendências',
    translateKey: '',
    icon: 'HiOutlineExclamation',
    type: NAV_ITEM_TYPE_ITEM,
    authority: ['admin'],
    subMenu: [],
  },
  {
    key: 'myNotificationsAdmin',
    path: '/my-notifications',
    title: 'Notificações',
    translateKey: '',
    icon: 'HiOutlineBell',
    type: NAV_ITEM_TYPE_ITEM,
    authority: ['admin'],
    subMenu: [],
  },

  {
    key: 'config',
    path: '/configurations',
    title: 'Configurações',
    translateKey: 'nav.config',
    icon: 'HiOutlineChartSquareBar',
    type: NAV_ITEM_TYPE_COLLAPSE,
    authority: ['admin'],
    subMenu: [
      {
        key: 'config.containterType',
        path: '/config/container-types',
        title: 'Tipos de Container',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'config.country',
        path: '/config/countries',
        title: 'Países',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'config.reports',
        path: '/config/reports',
        title: 'Relatórios',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'config.carriers',
        path: '/config/carriers',
        title: 'Transportadores',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'config.expenseType',
        path: '/config/expenses',
        title: 'Tipos de Despesa',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'config.marketNews',
        path: '/config/market-news',
        title: 'Notícias do Mercado',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'config.incoterms',
        path: '/config/incoterms',
        title: 'Incoterms',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'config.currency',
        path: '/config/currencies',
        title: 'Moedas',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'config.loadType',
        path: '/config/load-types',
        title: 'Tipos de Carga',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'config.locationTypes',
        path: '/config/location-types',
        title: 'Tipos de Localização',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'config.locations',
        path: '/config/locations',
        title: 'Localizações',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'config.modals',
        path: '/config/modals',
        title: 'Modais',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'config.packageTypes',
        path: '/config/package-types',
        title: 'Tipos de Embalagem',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'config.documentTypes',
        path: '/config/document-types',
        title: 'Tipos de Documento',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'config.customerManufacturer',
        path: '/config/customer-manufacturer',
        title: 'Fabricantes',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'config.customerProvider',
        path: '/config/customer-provider',
        title: 'Cadastro de Fornecedores',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'config.customerProduct',
        path: '/config/customer-product',
        title: 'Medicamentos',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'config.customerCustomer',
        path: '/config/customer-customer',
        title: 'Cadastro de Clientes',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'config.userProfile',
        path: '/config/user-profile',
        title: 'Perfis de usuários',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'config.notificationTypes',
        path: '/config/notification-types',
        title: 'Tipos de Notificação',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'config.useDashboard',
        path: '/config/use-dashboard',
        title: 'Dashboard de Uso',
        translateKey: 'nav.reports.myReports',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: ['admin'],
        subMenu: [],
      },
      {
        key: 'myIntegrators',
        path: '/integrators',
        title: 'Integrações',
        translateKey: '',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
      },
    ],
  },
];

export default navigationConfig;
