import { useState, useEffect } from 'react'; 

const Base64ModalViewer = ({ base64, mimeType }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const isImage = mimeType?.startsWith('image');
  const isPdf = mimeType?.startsWith('application/pdf');
  const src = `data:${mimeType};base64,${base64}`;

  useEffect(() => {
    if (isPdf && base64) {
      const pdfBlob = base64ToBlob(base64, 'application/pdf');
      setPdfUrl(URL.createObjectURL(pdfBlob));
    }
  }, [base64, isPdf]);

  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  return (
    <>
      {isImage && (
        <img
          src={src}
          title='Visualiza o Documento'
          className='max-w-full max-h-[80vh] mx-auto'
        />
      )}
      {isPdf && pdfUrl && (
        <iframe
          src={pdfUrl}
          title='Visualiza o Documento'
          className='w-full h-[80vh] border'
        />
      )}
    </>
  );
};

export default Base64ModalViewer;
