import api, { endpoint } from './api';

export async function apiGetAllShipments(params) {
  return api.get(endpoint.api_shipments, { params: params }).then((result) => {
    if (result.data) return result.data;
  });
}

export async function apiShipmentList(params) {
  return api
    .get(endpoint.api_shipment_list, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentTracking(params) {
  return api
    .get(endpoint.api_shipment_tracking, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentTracking2(params) {
  return api
    .get(endpoint.api_shipment_tracking2, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiGetShipmentsStatistics(params) {
  return api
    .get(endpoint.api_dashboard_shipment_statistics, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiGetShipmentsByStatisticStatus(params) {
  return api
    .get(endpoint.api_dashboard_shipment_by_statistics_status, {
      params: params,
    })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiGetShipmentById(params) {
  return api.get(endpoint.api_shipments, { params: params }).then((result) => {
    if (result.data) return result.data;
  });
}

export async function apiGetShipmentPendingsById(params) {
  return api
    .get(endpoint.api_shipment_pendings, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiGetShipmentPendings(params) {
  return api
    .get(endpoint.api_shipment_withpendings, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiGetShipmentMilestoneById(params) {
  return api
    .get(endpoint.api_shipment_milestone, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiGetShipmentTaxes(params) {
  return api
    .get(endpoint.api_shipment_taxes, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiGetShipmentCash(params) {
  return api
    .get(endpoint.api_shipment_cash, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiGetShipmentExpenses(params) {
  return api
    .get(endpoint.api_shipment_expenses, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiGetShipmentHistory(params) {
  return api
    .get(endpoint.api_shipment_history, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiSendShipmentHistory(params) {
  return api.post(endpoint.api_shipment_send_message, params).then((result) => {
    if (result.data) return result.data;
  });
}

export async function apiSendMessageAccessAsync(params) {
  return api.post(endpoint.api_dashboard_send_message_access, params).then((result) => {
    if (result.data) return result.data;
  });
}

export async function apiGetShipmentAttachments(params) {
  return api
    .get(endpoint.api_shipment_attachment, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentRemoveAttachment(params) {
  return api
    .delete(endpoint.api_shipment_attachment_remove, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentVolumeAdd(params) {
  return api.post(endpoint.api_shipment_volume_add, params).then((result) => {
    if (result.data) return result.data;
  });
}

export async function apiShipmentVolumeInvoiceAdd(params) {
  return api
    .post(endpoint.api_shipment_volume_invoice_add, params)
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentVolumeRemove(params) {
  return api
    .delete(endpoint.api_shipment_volume_remove, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentVolumeInvoiceRemove(params) {
  return api
    .delete(endpoint.api_shipment_volume_invoice_remove, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentAddAttachment(params) {
  return api
    .post(endpoint.api_shipment_attachment_add, params)
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentImportFinanceXml(params) {
	return api.post(endpoint.api_shipment_import_finance_xml, params).then((result) => {
		if (result.data) return result.data;
	});
}

export async function apiShipmentAttachmentDownload(params) {
  return api
    .get(endpoint.api_shipment_attachment_download, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentAttachmentDownloadAll(params) {
  return api
    .get(endpoint.api_shipment_attachment_download_all, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentGetVolumeInvoiceItems(params) {
  return api
    .get(endpoint.api_shipment_volume_invoice_items, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentAddInvoiceItem(params) {
  return api
    .post(endpoint.api_shipment_volume_invoice_item_add, params)
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentRemoveInvoiceItem(params) {
  return api
    .delete(endpoint.api_shipment_invoice_item_remove, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentRemovePending(params) {
  return api
    .delete(endpoint.api_shipment_pending_remove, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentRemove(params) {
  return api
    .delete(endpoint.api_shipment_remove, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentUpdate(params) {
  return api.put(endpoint.api_shipment_update, params).then((result) => {
    if (result.data) return result.data;
  });
}

export async function apiShipmentCreate(params) {
  return api.post(endpoint.api_shipment_create, params).then((result) => {
    if (result.data) return result.data;
  });
}

export async function apiShipmentTaxAdd(params) {
  return api.post(endpoint.api_shipment_tax_add, params).then((result) => {
    if (result.data) return result.data;
  });
}

export async function apiShipmentTaxRemove(params) {
  return api
    .delete(endpoint.api_shipment_tax_remove, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentExpenseAdd(params) {
  return api.post(endpoint.api_shipment_expense_add, params).then((result) => {
    if (result.data) return result.data;
  });
}

export async function apiShipmentExpenseRemove(params) {
  return api
    .delete(endpoint.api_shipment_expense_remove, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentExpensePaid(params) {
  return api
    .put(endpoint.api_shipment_expense_paid, params)
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentTaxPaid(params) {
  return api
    .put(endpoint.api_shipment_tax_paid, params)
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentCashAdd(params) {
  return api.post(endpoint.api_shipment_cash_add, params).then((result) => {
    if (result.data) return result.data;
  });
}

export async function apiShipmentCashRemove(params) {
  return api
    .delete(endpoint.api_shipment_cash_remove, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentUpdateStep(params) {
  return api.put(endpoint.api_shipment_update_step, params).then((result) => {
    if (result.data) return result.data;
  });
}

export async function apiShipmentUpdateActualStep(params) {
  return api
    .put(endpoint.api_shipment_update_actual_step, params)
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentUpdateLimitTimeArrive(params) {
  return api
    .put(endpoint.api_shipment_update_limit_time_arrive, params)
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentUpdatePartial(params) {
  return api
    .put(endpoint.api_shipment_update_partial, params)
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentUpdatePartialV2(params) {
  return api
    .put(endpoint.api_shipment_update_partial_v2, params)
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentMilestoneNextStep(params) {
  return api
    .post(endpoint.api_shipment_milestone_next_step, params)
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentMilestoneReturnStep(params) {
  return api
    .post(endpoint.api_shipment_milestone_return_step, params)
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentProcess(params) {
  return api
    .get(endpoint.api_shipment_process, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentFinance(params) {
  return api
    .get(endpoint.api_shipment_finance, { params: params })
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentUpdateStepOrder(data) {
  return api
    .put(endpoint.api_shipment_milestone_step_order, data)
    .then((result) => {
      if (result.data) return result.data;
    });
}

export async function apiShipmentUpdateStepStatus(data) {
  return api
    .put(endpoint.api_shipment_milestone_step_status, data)
    .then((result) => {
      if (result.data) return result.data;
    })
    .catch((error) => {
      return error?.response?.data;
    });
}

export async function apiShipmentMilestoneCreateStep(data) {
  return api
    .post(endpoint.api_shipment_milestone, data)
    .then((result) => {
      if (result.data) return result.data;
    })
    .catch((error) => {
      return error?.response?.data;
    });
}

export async function apiGetShipmentOrderItems(params) {
  return api
    .get(endpoint.api_shipment_orderitems, { params: params })
    .then((result) => {
      if (result.data) {
        return result.data;
      }
    });
}
