import ShipmentUpdateActionTypes from './action-types';

const initialState = {
  data: [],
  matchCarrier: [],
  columns: [
    {
      name: 'Referência do Embarque',
      description:
        'Sua referência interna do embarque, necessário para identificar o embarque de forma única.',
    },
    {
      name: 'Modal',
      description:
        'Modal do embarque podendo ser: A = Aéreo, M = Marítimo, R = Rodoviário.',
    },
    {
      name: 'Transportador',
      description: 'Nome do Transportador.',
    },
    {
      name: 'Número de Rastreio',
      description: 'Número de rastreio do embarque.',
    },
    {
      name: 'Tipo de Rastreio',
      description:
        'Identifica o tipo de rastreio, sendo requerido apenas no modal marítimo, exemplo: BL, Container, Booking.',
    },
  ],
  currentStep: 1,
  loading: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ShipmentUpdateActionTypes.LOAD_FILE_IMPORT:
      return {
        ...state,
        data: action.payload,
      };
    case ShipmentUpdateActionTypes.NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case ShipmentUpdateActionTypes.PREV_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case ShipmentUpdateActionTypes.CANCEL_IMPORT:
      return initialState;
    case ShipmentUpdateActionTypes.LOAD_MATCH_CARRIER:
      return {
        ...state,
        matchCarrier: action.payload,
      };
    case ShipmentUpdateActionTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
