const ShipmentUpdateActionTypes = {
    LOAD_FILE_IMPORT: 'shipment_update/load_file_import',
    NEXT_STEP: 'shipment_update/next_step',
    PREV_STEP: 'shipment_update/prev_step',
    CANCEL_IMPORT: 'shipment_update/cancel_import',
    LOAD_MATCH_CARRIER: 'shipment_update/load_match_carrier',
    LOADING: 'shipment_update/loading',
    UPDATE_DATA_IMPORT: 'shipment_update/update_data_import',
  };
  
  export default ShipmentUpdateActionTypes;