import AgentActionTypes from './action-types';

const initialState = {
  data: [],
  columns:[{
    name: 'CNPJ',
    description: 'Indica o CNPJ do despachante.',
  },{
    name: 'Razão Social',
    description: 'Indica a razão social do despachante.',
  }, {
    name: 'Nome Fantasia',
    description: 'Indica o nome fantasia do despachante.',
  },{
    name: 'E-mail de Notificação',
    description: 'Indica o email de contato para notificações sobre o processo.'
  },{
    name: 'Avaliação',
    description: 'Indica a avaliação do despachante.'
  }],
  currentStep: 1,
  loading: false,
};

const agentReducer = (state = initialState, action) => {
  switch (action.type) {
    case AgentActionTypes.LOAD_FILE_IMPORT:
      return {
        ...state,
        data: action.payload,
      };
    case AgentActionTypes.NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case AgentActionTypes.PREV_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case AgentActionTypes.CANCEL_IMPORT:
      return initialState;
    case AgentActionTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default agentReducer;
