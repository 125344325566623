import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import locale from './locale/localeSlice'
import shipment from './shipment'
import shipmentUpdate from './shipmentUpdate'
import partner from './partner'
import agent from './agent'
import supplier from './supplier'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        locale,
        shipment,
        shipmentUpdate,
        partner,
        agent,
        supplier,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
