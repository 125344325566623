import SupplierActionTypes from './action-types';

const initialState = {
  data: [],
  columns: [
    {
      name: 'Nome do Fornecedor',
      description: 'Indica o nome do fornecedor.',
    },
    {
      name: 'Email',
      description: 'Endereço de e-mail do fornecedor.',
    },
  ],
  currentStep: 1,
  loading: false,
};

const supplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case SupplierActionTypes.LOAD_FILE_IMPORT:
      return {
        ...state,
        data: action.payload,
      };
    case SupplierActionTypes.NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case SupplierActionTypes.PREV_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case SupplierActionTypes.CANCEL_IMPORT:
      return initialState;
    case SupplierActionTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default supplierReducer;
