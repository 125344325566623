import PartnerActionTypes from './action-types';

const initialState = {
  data: [],
  columns:[{
    name:'CNPJ',
    description: 'Indica o CNPJ da empresa operadora de cargas.'
  },{
    name: 'Razão Social',
    description: 'Indica a razão social da empresa operadora.'
  },{
    name: 'Nome Fantasia',
    description: 'Indica o nome fantasia da empresa operadora.'
  },{
    name: 'Email do Pricing',
    description: 'Indica o email de contato para solicitacoes de preços.'
  },{
    name: 'Email de Notificação',
    description: 'Indica o email de contato para notificações sobre o processo.'
  }, {
    name: 'Avaliação',
    description: 'Indica a avaliação da empresa fornecedora.'
  }],
  currentStep: 1,
  loading: false,
};

const partnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case PartnerActionTypes.LOAD_FILE_IMPORT:
      return {
        ...state,
        data: action.payload,
      };
    case PartnerActionTypes.NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case PartnerActionTypes.PREV_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case PartnerActionTypes.CANCEL_IMPORT:
      return initialState;
    case PartnerActionTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default partnerReducer;
