import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import mockServer from './mock'
import appConfig from 'configs/app.config'
import './locales'
import { apiSendMessageAccessAsync } from 'services/ShipmentService'
import navigationConfig from 'configs/navigation.config/index.js'


const environment = process.env.NODE_ENV

/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */
// if (environment !== 'production' && appConfig.enableMock) {
//mockServer({ environment })
// }

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <AppContent />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    )
}

function AppContent() {
    const location = useLocation();

    useEffect(() => {
        const pathSegments = location.pathname.split('/');
        const lastSegment = pathSegments[pathSegments.length - 1];        

        if (lastSegment === 'sign-in' || lastSegment === "")
            return;
        
        const findTitleByPath = (path, config) => {
            for (const item of config) {
                if (item.path.endsWith(path)) {
                    return item.title;
                }
                if (item.subMenu && item.subMenu.length > 0) {
                    const subMenuTitle = findTitleByPath(path, item.subMenu);
                    if (subMenuTitle) {
                        return subMenuTitle;
                    }
                }
            }
            return null;
        };

        const title = findTitleByPath(lastSegment, navigationConfig);

        if (title) {
            var filter = {
                Module: title
            };

            apiSendMessageAccessAsync(filter);
        }
    
    }, [location]);

    return (
        <Theme>
            <Layout />
        </Theme>
    );
}

export default App;